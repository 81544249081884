<template>
  <div class="filter">
    <div class="filter__group-title">{{ cardCount }} cards</div>
    <div class="filter__search-group">
      <input type="text" placeholder="Search" class="filter__search field" />
      <Search />
    </div>
    <div class="filter__sort" v-if="sort">
      <div
        class="filter__sort-select"
        @click="openSelect()"
        v-click-outside="closeSelect"
      >
        {{ this.sortTitle }}
        <Arrow
          :class="['filter__select-arrow', selectOpen ? 'openSelectSvg' : '']"
        />
      </div>
      <div class="filter__sort-body" v-if="selectBody" @click="chooseoption()">
        <div class="filter__sort-item">Cheaper at first</div>
        <div class="filter__sort-item">Cheaper at first</div>
        <div class="filter__sort-item">Cheaper at first</div>
        <div class="filter__sort-item">Cheaper at first</div>
        <div class="filter__sort-item">Cheaper at first</div>
      </div>
    </div>
    <div class="filter__group-checkbox">
      <div v-for="titleCheckbox in dataTitleCheckbox" :key="titleCheckbox.id">
        <div class="filter__group-title-checkbox">{{ titleCheckbox }}</div>
        <div v-if="titleCheckbox === 'Type'">
          <div v-for="item in dataBodyCheckbox" :key="item.id">
            <div v-for="filter in item.Type" :key="filter.id">
              <div class="filter__checkbox">
                <label :for="filter" class="custom-radio">
                  <input type="checkbox" :id="filter" />
                  <span>{{ filter }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div v-if="titleCheckbox === 'Gender'">
          <div v-for="item in dataBodyCheckbox" :key="item.id">
            <div v-for="filter in item.Gender" :key="filter.id">
              <div class="filter__checkbox">
                <label :for="filter" class="custom-radio">
                  <input type="checkbox" :id="filter" />
                  <span>{{ filter }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div v-if="titleCheckbox === 'Rarity'">
          <div v-for="item in dataBodyCheckbox" :key="item.id">
            <div v-for="filter in item.Rarity" :key="filter.id">
              <div class="filter__checkbox">
                <label :for="filter" class="custom-radio">
                  <input type="checkbox" :id="filter" />
                  <span>{{ filter }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="price">
      <div class="filter__group-title">Price OpenSea, SOL</div>
      <div class="filter__price-group">
        <div class="filter__price"></div>
        -
        <div class="filter__price"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Search from "@/assets/svg/elements/search.svg?inline";
import Arrow from "@/assets/svg/elements/arrow.svg?inline";

export default defineComponent({
  name: "Filter",
  data() {
    return {
      sortTitle: "Sort by",
      selectBody: false,
      selectOpen: false,
    };
  },
  props: {
    slidesCollect: {},
    sort: Boolean,
    price: Boolean,
    dataTitleCheckbox: {
      type: Array,
      default: null,
    },
    dataBodyCheckbox: {
      type: Array,
      default: null,
    },
    cardCount: {
      type: Number,
    }
  },
  components: {
    Search,
    Arrow,
  },
  methods: {
    openSelect() {
      if (this.selectBody === false) {
        this.selectBody = true;
        this.selectOpen = true;
      } else {
        this.selectBody = false;
        this.selectOpen = false;
      }
    },
    closeSelect() {
      this.selectBody = false;
      this.selectOpen = false;
    },
    chooseoption() {
      this.sortTitle = "Cheaper at first";
    },
  },
});
</script>

<style lang="scss" scoped>
.filter {
  &__group-title {
    padding-bottom: 15px;
  }

  &__group-title-checkbox {
    padding: 15px 0;
  }

  &__search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #000000;
    border-radius: 5px;
    width: 280px;
    height: 48px;
    padding: 0 10px;
    margin-bottom: 20px;
    background: #ffffff;
  }

  &__sort {
    position: relative;
  }

  &__sort-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #000000;
    border-radius: 5px;
    width: 280px;
    height: 48px;
    padding: 0 10px;
    margin-bottom: 25px;
    background: #ffffff;
    position: relative;
    z-index: 1;

    &:hover {
      cursor: pointer;
    }
  }

  &__search-group {
    position: relative;
    width: 280px;

    svg {
      position: absolute;
      top: 14px;
      right: 10px;
    }
  }

  &__sort-body {
    background: #ffffff;
    border: 1px solid #311ffe;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 5px 5px;
    width: 280px;
    position: absolute;
    top: 45px;
    animation: ani 1s forwards;
  }
  @keyframes ani {
    0% {
      transform: translateY(-20%);
    }
    100% {
      transform: translateY(0);
    }
  }

  &__sort-item {
    padding: 10px;

    &:hover {
      cursor: pointer;
      @include linkMenuHover;
    }
  }

  &__checkbox {
    padding-bottom: 10px;
  }

  &__group-checkbox {
    margin-bottom: 25px;
  }

  &__price-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 280px;
    background: white;
  }

  &__price {
    display: flex;
    align-items: center;
    border: 1px solid #000000;
    border-radius: 5px;
    width: 130px;
    height: 48px;
  }
  .openSelectSvg {
    transform: rotate(180deg);
    transition: all 0.5s ease-out;
  }
}

@media (max-width: $break-xlg) {
  .filter {
    display: block;
  }
}

@media (max-width: $break-md) {
  .filter {
    &__container {
      width: 400px;
    }
  }
}

@media (max-width: $break-sm) {
  .filter {
    &__sort-select {
      z-index: 0;
    }
  }
}
</style>
